import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { setIdLookup } from 'lib/helpers';

import {
  downloadDesign,
  downloadStarter,
  fetchChallenge,
  fetchChallenges,
} from './thunks';

import {
  selectCompletedChallenges,
  selectInProgressChallenges,
} from 'features/auth/slice';
import {
  fetchCurrentUserBookmarks,
  fetchCurrentUserChallenges,
  fetchCurrentUserPathProgresses,
  fetchCurrentUserSolutions,
} from 'features/auth/slice/thunks';
import {
  fetchLearningPaths,
  fetchLearningPath,
} from 'features/learningPaths/slice';
import { fetchSolution } from 'features/solutions/slice/thunks';
import {
  fetchLearnerComments,
  fetchLearnerSolutions,
} from 'features/learners/slice';

export const adapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

const upsertAssociated = (state, action) => {
  adapter.upsertMany(state, action.payload.associations.challenges || []);
  setIdLookup(state, action.payload.associations.challenges || [], 'slug');
};

const slice = createSlice({
  name: 'challenges',
  initialState: adapter.getInitialState({
    error: null,
    hydrated: false,
    idLookup: {},
    page: { current: 0, next: null, prev: null, total: 0 },
    status: 'idle',
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChallenge.fulfilled, (state, action) => {
        adapter.upsertOne(state, action.payload.data);
        setIdLookup(state, action.payload.data || [], 'slug');
      })
      .addCase(fetchChallenges.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data);
        setIdLookup(state, action.payload.data || [], 'slug');
        state.page = action.payload.page;
        state.status = 'idle';
        state.error = null;
      })
      .addCase(fetchChallenges.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchChallenges.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchCurrentUserBookmarks.fulfilled, upsertAssociated)
      .addCase(fetchCurrentUserChallenges.fulfilled, (state, action) => {
        adapter.upsertMany(state, action.payload.data);
        setIdLookup(state, action.payload.data || [], 'slug');
      })
      .addCase(fetchCurrentUserPathProgresses.fulfilled, upsertAssociated)
      .addCase(fetchCurrentUserSolutions.fulfilled, upsertAssociated)
      .addCase(fetchLearningPaths.fulfilled, upsertAssociated)
      .addCase(fetchLearningPath.fulfilled, upsertAssociated)
      .addCase(fetchSolution.fulfilled, upsertAssociated)
      .addCase(fetchLearnerComments.fulfilled, upsertAssociated)
      .addCase(fetchLearnerSolutions.fulfilled, upsertAssociated)
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.challenges,
          hydrated: true,
        };
      });
  },
});

export { downloadDesign, downloadStarter, fetchChallenge, fetchChallenges };

export const {
  selectAll: selectChallenges,
  selectById: selectChallenge,
  selectIds: selectChallengeIds,
} = adapter.getSelectors((state) => state.challenges);

export const selectChallengeBySlug = (state, slug) =>
  selectChallenge(state, state.challenges.idLookup[slug]);

export const selectCanAccessDesigns = (state, challenge) => {
  if (challenge.type === 'free-plus') return true;
  if (state.auth.currentUser?.purchases.includes(challenge.id)) return true;

  return !!state.auth.currentUser?.credits.designs;
};

export const selectCompletedChallengeIds = createSelector(
  [(state) => selectCompletedChallenges(state)],
  (challenges) => challenges.map((challenge) => challenge.id)
);

export const selectInProgressChallengeIds = createSelector(
  [(state) => selectInProgressChallenges(state)],
  (challenges) => challenges.map((challenge) => challenge.id)
);

export const selectNextSolutionPage = (state, id) => {
  const challenge = state.challenges.entities[id];
  if (!challenge?.solutions) return null;
  if (challenge.solutions.length >= challenge.solutionCount) return null;

  if ((challenge.solutions.length / 12) % 1 === 0) {
    return challenge.solutions.length / 12 + 1;
  }

  return null;
};

export default slice.reducer;
