import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { selectEntity, setIdLookup } from 'lib/helpers';
import { recordsMatch } from '@frontendmentorio/design-system-v2';

import {
  deleteCurrentUser,
  fetchCurrentUserBookmarks,
  fetchCurrentUserSolutions,
  fetchCurrentUserPathProgresses,
} from 'features/auth/slice/thunks';
import { submitStepStage } from 'features/pathProgresses/slice/thunks';
import { selectTagsById } from 'features/tags/slice';
import {
  fetchLearnerComments,
  fetchLearnerSolutions,
} from 'features/learners/slice/thunks';

import {
  fetchSolution,
  createSolution,
  updateSolution,
  deleteSolution,
  fetchSolutionComments,
  bookmarkSolution,
  likeSolution,
  generateScreenshot,
  generateReport,
} from './thunks';

export const adapter = createEntityAdapter({
  sortComparer: (a, b) =>
    b.submittedAt
      ? b.submittedAt.localeCompare(a.submittedAt)
      : b.id.localeCompare(a.id),
});

const upsertAssociated = (state, action) => {
  adapter.upsertMany(state, action.payload.associations?.solutions || []);
  setIdLookup(state, action.payload.associations?.solutions || [], 'slug');
};

const upsertRecords = (state, action) => {
  const records = [].concat(action.payload.data);
  adapter.upsertMany(state, records);
  setIdLookup(state, records, 'slug');
};

const slice = createSlice({
  name: 'solutions',
  initialState: adapter.getInitialState({
    error: null,
    hydrated: false,
    idLookup: {},
    status: 'idle',
  }),
  extraReducers: (builder) => {
    builder
      .addCase(bookmarkSolution.fulfilled, upsertRecords)
      .addCase(createSolution.fulfilled, upsertRecords)
      .addCase(deleteCurrentUser.fulfilled, (state, action) => {
        const userSolutions = Object.values(state.entities).filter(
          (solution) => solution.user !== action.payload.meta.id
        );
        adapter.removeMany(
          state,
          userSolutions.map((solution) => solution.id)
        );
      })
      .addCase(deleteSolution.fulfilled, (state, action) => {
        adapter.removeOne(state, action.payload.meta.id);
      })
      .addCase(fetchCurrentUserBookmarks.fulfilled, upsertRecords)
      .addCase(fetchCurrentUserPathProgresses.fulfilled, upsertAssociated)
      .addCase(fetchCurrentUserSolutions.fulfilled, (state, action) => {
        upsertAssociated(state, action);
        upsertRecords(state, action);
      })
      .addCase(fetchLearnerComments.fulfilled, upsertAssociated)
      .addCase(fetchSolution.fulfilled, upsertRecords)
      .addCase(fetchSolutionComments.fulfilled, (state, action) => {
        const solution = selectEntity(state, action.meta.arg.id);
        solution.comments = action.payload.data.map((record) => record.id);
      })
      .addCase(fetchLearnerSolutions.fulfilled, upsertRecords)
      .addCase(generateReport.fulfilled, upsertRecords)
      .addCase(generateScreenshot.fulfilled, upsertRecords)
      .addCase(likeSolution.fulfilled, upsertRecords)
      .addCase(submitStepStage.fulfilled, upsertAssociated)
      .addCase(updateSolution.fulfilled, upsertRecords)
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.solutions,
          hydrated: true,
        };
      });
  },
});

export {
  fetchSolution,
  createSolution,
  updateSolution,
  deleteSolution,
  fetchSolutionComments,
  bookmarkSolution,
  likeSolution,
  generateScreenshot,
  generateReport,
};

export const {
  selectAll: selectSolutions,
  selectById: selectSolution,
  selectIds: selectSolutionIds,
} = adapter.getSelectors((state) => state.solutions);

export const selectSolutionBySlug = (state, slug) =>
  selectSolution(state, state.solutions.idLookup[slug]);

export const selectSolutionsById = createSelector(
  [(state) => state.solutions, (state, ids) => ids],
  (solutions, ids = []) => {
    return ids
      .map((id) => solutions.entities[id])
      .filter((solution) => !!solution);
  }
);

export const selectSolutionsByUserId = createSelector(
  [(state) => state.solutions, (state, id) => id],
  (solutions, id) => {
    return Object.values(solutions.entities).filter((solution) =>
      recordsMatch(solution.user, id)
    );
  }
);

export const selectSkillsBySolutionIds = createSelector(
  [(state) => state, (state, ids) => ids],
  (state, ids = []) => {
    const solutions = selectSolutionsById(state, ids);
    const incJS = solutions.some((solution) =>
      state.challenges.entities[solution.challenge]?.languages.includes('JS')
    );
    const baseSkills = [
      { id: 'html', displayName: 'HTML' },
      { id: 'css', displayName: 'CSS' },
      ...(incJS ? [{ id: 'javascript', displayName: 'JavaScript' }] : []),
    ];
    const uniqTagIds = solutions.reduce(
      (tags, solution) => [...new Set([...tags, ...solution.tags])],
      []
    );
    const skills = selectTagsById(state, uniqTagIds).filter(
      (tag) => tag.isSkill
    );
    return ids.length ? [...baseSkills, ...skills] : [];
  }
);

export const selectNextPage = (state) => state.solutions.page.next;
export const selectIsLoading = (state) => state.solutions.status === 'loading';

export default slice.reducer;
