import axios from 'axios';
import { createAsyncReducer, createCrudReducers } from '@app/crudReducers';

export const {
  fetchAll: fetchSolutions,
  fetchById: fetchSolution,
  create: createSolution,
  update: updateSolution,
  delete: deleteSolution,
} = createCrudReducers('solutions', `${process.env.REST_API_URL}/v2/solutions`);

export const fetchSolutionComments = createAsyncReducer(
  'solutions/comments',
  async ({ id, headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/solutions/${id}/comments`,
        null,
        { headers }
      )
    ).data;
  },
  {
    condition: ({ id } = {}, { getState }) => {
      const state = getState().solutions;
      const solution = state.entities[id] || state.entities[state.idLookup[id]];

      if (!solution) return false;
      if (solution.comments) return false;

      return true;
    },
  }
);

export const bookmarkSolution = createAsyncReducer(
  'solutions/bookmark',
  async ({ id, headers }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/solutions/${id}/bookmark`,
        null,
        { headers }
      )
    ).data;
  }
);

export const likeSolution = createAsyncReducer(
  'solutions/like',
  async ({ id, headers }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/solutions/${id}/like`,
        null,
        { headers }
      )
    ).data;
  }
);

export const generateScreenshot = createAsyncReducer(
  'solutions/screenshot',
  async ({ id, headers }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/solutions/${id}/screenshot`,
        null,
        { headers }
      )
    ).data;
  },
  {
    successMessage: 'New screenshot created',
  }
);

export const generateReport = createAsyncReducer(
  'solutions/report',
  async ({ id, headers }) => {
    return (
      await axios.post(
        `${process.env.REST_API_URL}/v2/solutions/${id}/report`,
        null,
        { headers }
      )
    ).data;
  },
  {
    successMessage: 'New report generated',
  }
);
