import axios from 'axios';
import { createAsyncReducer, createCrudReducers } from '@app/crudReducers';

export const { fetchAll: fetchChallenges, fetchById: fetchChallenge } =
  createCrudReducers(
    'challenges',
    `${process.env.REST_API_URL}/v2/challenges`,
    {
      fetchAll: {
        condition: (_, { getState }) => {
          const state = getState().challenges;

          return state.ids.length < 100;
        },
      },
    }
  );

export const downloadDesign = createAsyncReducer(
  'challenges/design',
  async ({ id, headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/challenges/${id}/design`,
        {
          params: { type: 'figma' },
          headers,
        }
      )
    ).data;
  },
  {
    onSuccess: async (response) => {
      window.location.assign(response.meta.downloadUrl);
    },
  }
);

export const downloadStarter = createAsyncReducer(
  'challenges/starter',
  async ({ id, headers }) => {
    return (
      await axios.get(
        `${process.env.REST_API_URL}/v2/challenges/${id}/starter`,
        { headers }
      )
    ).data;
  },
  {
    onSuccess: (response) => {
      window.location.assign(response.meta.downloadUrl);
    },
  }
);
