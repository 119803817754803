import React, { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { SWRConfig as SWRProvider } from 'swr';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { hasCookie } from 'cookies-next';
import * as Fathom from 'fathom-client';
import { wrapper } from '@app/configureStore';
import { Barlow, Heebo } from 'next/font/google';

import Error from 'features/pages/500';

import { InteractionProvider } from 'lib/contexts/InteractionContext';
import { NotificationProvider } from 'lib/contexts/NotificationContext';
import { SocketProvider } from 'lib/contexts/SocketContext';
import { SubscriptionProvider } from 'lib/contexts/SubscriptionContext';
import { fetchCurrentUser } from 'features/auth/slice';
import { fetchConversations } from 'features/conversations/slice';
import { fetchTags } from 'features/tags/slice';

import 'globals.css';

const barlow = Barlow({
  style: ['italic', 'normal'],
  subsets: ['latin'],
  variable: '--font-barlow',
  weight: ['500', '600', '700'],
});

const heebo = Heebo({
  subsets: ['latin'],
  variable: '--font-heebo',
  weight: ['400', '500', '600', '900'],
});

function LearningPlatform({ Component, ...rest }) {
  const { store, props } = wrapper.useWrappedStore(rest);
  const router = useRouter();

  useEffect(() => {
    if (!hasCookie('fem_token')) return;

    store.dispatch(fetchCurrentUser());
    store.dispatch(fetchConversations());
  }, []);

  useEffect(() => {
    Fathom.load('VHCRKCXI', {
      includedDomains: ['frontendmentor.io', 'www.frontendmentor.io'],
    });

    function handleRouteChange() {
      Fathom.trackPageview();
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <SWRProvider>
      <ReduxProvider store={store}>
        {props.hasError ? (
          <Error isServer={props.isServer} statusCode={props.statusCode} />
        ) : (
          <SocketProvider>
            <SubscriptionProvider>
              <InteractionProvider>
                <NotificationProvider>
                  <Head>
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1, viewport-fit=cover"
                    />
                  </Head>

                  <div
                    className={`${barlow.variable} ${heebo.variable} font-primary`}
                    id="modal-root"
                  >
                    <Component {...props.pageProps} />
                  </div>
                </NotificationProvider>
              </InteractionProvider>
            </SubscriptionProvider>
          </SocketProvider>
        )}
      </ReduxProvider>
    </SWRProvider>
  );
}

LearningPlatform.getInitialProps = wrapper.getInitialPageProps(
  (store) =>
    async ({ Component, ctx }) => {
      if (ctx.req) await store.dispatch(fetchTags());

      try {
        const pageProps = Component?.getInitialProps
          ? await Component.getInitialProps({ store, ...ctx })
          : {};

        return { pageProps, isServer: ctx.isServer };
      } catch (error) {
        return {
          hasError: true,
          isServer: ctx.isServer,
          statusCode: error.status,
        };
      }
    }
);

export default LearningPlatform;
