import { useState } from 'react';
import React, { createContext } from 'react';

export const InteractionContext = createContext();

export const InteractionProvider = ({ children }) => {
  const [store, setStore] = useState({});

  const removeInteractions = (entity) => {
    setStore((current) => {
      delete current[entity.id];
      return current;
    });
  };

  const getInteractions = (entity) => {
    return { ...entity, ...(store[entity.id] || {}) };
  };

  const setInteractions = (entity, interactions) => {
    const existing = store[entity.id] || {};
    setStore({ ...store, [entity.id]: { ...existing, ...interactions } });
  };

  return (
    <InteractionContext.Provider
      value={{
        removeInteractions,
        getInteractions,
        setInteractions,
      }}
    >
      {children}
    </InteractionContext.Provider>
  );
};
