import { getCookie, deleteCookie, setCookie } from 'cookies-next';
import { cloneDeep } from 'lodash';

const IS_DEPLOYED = process.env.NODE_ENV.match(/staging|production/);
const COOKIE_DOMAIN = IS_DEPLOYED && '.frontendmentor.io';

export const setRedirectPath = () => {
  setCookie('redirectPath', window.location.pathname);
};

export const getRedirectPath = () => {
  const path = getCookie('redirectPath');
  deleteCookie('redirectPath');

  return path;
};

export const setAuthToken = (token) => {
  setCookie('fem_token', token, {
    expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'lax',
    domain: COOKIE_DOMAIN,
  });
};

export const clearAuthToken = () => {
  deleteCookie('fem_token', { domain: COOKIE_DOMAIN });
};

export const getRequestHeaders = (ctx) => {
  const token = getCookie('fem_token', ctx);
  const ipAddress =
    ctx?.req?.headers['cf-connecting-ip'] || ctx?.req?.connection.remoteAddress;

  return {
    ...(token && { Authorization: `Bearer ${token}` }),
    ...(ipAddress && { 'X-Forwarded-For': ipAddress }),
  };
};

export const convertToOptions = (
  records = [],
  { labelKey = 'name', valueKey = 'id' } = {}
) => {
  return records.map((record) => ({
    value: record[valueKey],
    label: record[labelKey],
  }));
};

export const setIdLookup = (state, payload, lookupKey) => {
  payload = [].concat(payload);
  state.idLookup = state.idLookup || {};
  Object.assign(
    state.idLookup,
    Object.fromEntries(payload.map((item) => [item[lookupKey], item.id]))
  );
};

export const setPagedResultIds = (state, data) => {
  if (!state.pagedResultIds) return;

  state.pagedResultIds = state.pagedResultIds.concat(
    data.map((record) => record.id)
  );
};

export const selectEntity = (state, id) => {
  return state.entities[state.idLookup?.[id] || id];
};

const parseFilterValue = (value, expected) => {
  if (Array.isArray(expected)) {
    return value.split(',').map((item) => (isNaN(item) ? item : Number(item)));
  }

  if (typeof expected === 'boolean') return value === 'true';

  return value;
};

export const getFiltersFromQuery = (query, defaults = {}) => {
  const filters = cloneDeep(defaults);
  const params = new URLSearchParams(query);

  for (const [key, value] of params) {
    if (defaults[key]) {
      filters[key] = parseFilterValue(value, defaults[key]);
    }
  }

  return filters;
};

export const getQueryFromFilters = (filters, defaults = {}) => {
  const queryFilters = {};

  Object.keys(defaults).forEach((key) => {
    if (filters[key].toString() !== defaults[key].toString()) {
      queryFilters[key] = filters[key];
    }
  });

  return new URLSearchParams(queryFilters).toString();
};

export const parseFilters = (filters, defaults = {}) => {
  const newFilters = cloneDeep(defaults);

  for (const key in defaults) {
    if (filters[key] && defaults[key] instanceof Array) {
      newFilters[key] = []
        .concat(filters[key])
        .map((item) => (isNaN(item) ? item : Number(item)));
    } else if (filters[key]) {
      newFilters[key] = filters[key];
    }
  }

  return newFilters;
};

export const getStepNavUrls = (router, progress) => {
  if (!progress) return { current: { isActive: false, url: null } };

  const stage = `${router.query.stepId}/${router.query.type}/${router.query.stage}`;
  const idx = progress.track.indexOf(stage);
  const currentIdx = progress.track.indexOf(progress.currentStage);
  const baseUrl = `/learning-paths/${router.query.slug}/steps`;
  const next = progress.track[idx + 1];
  const prev = progress.track[idx - 1];

  return {
    current: { isActive: idx <= currentIdx, url: `${baseUrl}/${stage}` },
    next: next
      ? {
          isActive: idx + 1 <= currentIdx,
          url: `${baseUrl}/${next}`,
        }
      : null,
    prev: next
      ? {
          isActive: idx - 1 <= currentIdx,
          url: `${baseUrl}/${prev}`,
        }
      : null,
  };
};

export const hasRetro = (solution) => {
  return Boolean(
    Object.values(solution?.retrospective || {}).join('') || solution.questions
  );
};

export const sanitizeQueryRegex = (query = '') => {
  return new RegExp(query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
};

export const updateRecordInCollection = (
  collection = [],
  matcher,
  callback
) => {
  const record = collection.find(matcher);
  const idx = collection.indexOf(record);

  if (!record) return collection;

  return [
    ...collection.slice(0, idx),
    callback(record),
    ...collection.slice(idx + 1),
  ];
};
