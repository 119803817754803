import * as Sentry from '@sentry/nextjs';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { findLast } from 'lodash';
import { clearAuthToken } from 'lib/helpers';
import { recordsMatch, selectProps } from '@frontendmentorio/design-system-v2';

import {
  deactivateSubscription,
  deleteCurrentUser,
  fetchCurrentUser,
  fetchCurrentUserBookmarks,
  fetchCurrentUserChallenges,
  fetchCurrentUserFollowing,
  fetchCurrentUserFollowers,
  fetchCurrentUserPathProgresses,
  fetchCurrentUserSolutions,
  fetchInvoices,
  followChallenge,
  followTag,
  followUser,
  login,
  payInvoice,
  reactivateSubscription,
  startLearningPath,
  updateCurrentUser,
} from './thunks';

import { downloadDesign } from 'features/challenges/slice';

import { submitStepStage } from 'features/pathProgresses/slice/thunks';

import {
  bookmarkSolution,
  generateScreenshot,
  createSolution,
} from 'features/solutions/slice/thunks';

import { selectSolutionsById } from 'features/solutions/slice';

export const PRIVATE_PROPS = [
  'admin',
  'bookmarks',
  'challenges',
  'credits',
  'discount',
  'email',
  'followers',
  'following',
  'purchases',
  'settings',
  'subscription',
  'tags',
  'talentProfile',
  'unlockedHubs',
  'unlockedHubSolutions',
  'workPreferences',
];

const setCurrentUser = (state, action) => {
  state.error = null;
  state.currentUser = selectProps(action.payload.data, [
    'id',
    ...PRIVATE_PROPS,
  ]);

  state.status = 'idle';

  Sentry.setUser({
    id: state.currentUser.id,
    email: state.currentUser.email,
    username: state.currentUser.username,
  });
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    currentUser: null,
    error: null,
    hydrated: false,
    invoices: [],
    status: 'idle',
  },
  reducers: {
    removeDiscount: (state) => {
      delete state.currentUser.discount;
    },
    signOut: (state) => {
      clearAuthToken();
      state.currentUser = null;
      state.invoices = [];
      Sentry.setUser(null);
    },
    lockChallenge: (state, action) => {
      state.currentUser.unlockedHubs = state.currentUser.unlockedHubs.filter(
        (item) => !recordsMatch(item, action.payload)
      );
    },
    unlockChallenge: (state, action) => {
      state.currentUser.unlockedHubs.push(action.payload.id);
      if (action.payload.type === 'free') return;

      state.currentUser.purchases.push(action.payload.id);
      state.currentUser.credits.premium -= 1;
    },
    spendCredit: (state, action) => {
      state.currentUser.credits[action.payload] -= 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(bookmarkSolution.fulfilled, (state, action) => {
        if (!state.currentUser.bookmarks) return;
        state.currentUser.bookmarks = action.payload.meta.bookmarks;
      })
      .addCase(createSolution.fulfilled, (state, action) => {
        state.currentUser.mentorScore = action.payload.meta.mentorScore;
      })
      .addCase(deactivateSubscription.fulfilled, (state, action) => {
        state.error = null;
        state.currentUser.subscription = action.payload;
      })
      .addCase(deleteCurrentUser.fulfilled, (state) => {
        state.currentUser = null;
      })
      .addCase(downloadDesign.fulfilled, (state, action) => {
        state.currentUser.credits = action.payload.meta.credits;
        state.currentUser.purchases = action.payload.meta.purchases;
      })
      .addCase(fetchCurrentUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCurrentUser.fulfilled, setCurrentUser)
      .addCase(fetchCurrentUser.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = 'idle';
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.error = null;
        state.invoices = action.payload.data;
      })
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchCurrentUserBookmarks.fulfilled, (state, action) => {
        state.currentUser.bookmarks = action.payload.data.map(
          (solution) => solution.id
        );
      })
      .addCase(fetchCurrentUserFollowing.fulfilled, (state, action) => {
        state.currentUser.following = action.payload.data.map(
          (user) => user.id
        );
      })
      .addCase(fetchCurrentUserFollowers.fulfilled, (state, action) => {
        state.currentUser.followers = action.payload.data.map(
          (user) => user.id
        );
      })
      .addCase(fetchCurrentUserPathProgresses.fulfilled, (state, action) => {
        state.currentUser.pathProgresses = action.payload.data.map(
          (path) => path.id
        );
      })
      .addCase(followChallenge.fulfilled, (state, action) => {
        state.currentUser.challenges = action.payload.meta.challenges;
      })
      .addCase(followTag.fulfilled, (state, action) => {
        state.currentUser.tags = action.payload.meta.tags;
      })
      .addCase(followUser.fulfilled, (state, action) => {
        state.currentUser.following = action.payload.meta.following;
      })
      .addCase(generateScreenshot.fulfilled, (state, action) => {
        state.currentUser.credits = action.payload.meta.credits;
      })
      .addCase(login.fulfilled, setCurrentUser)
      .addCase(payInvoice.fulfilled, (state, action) => {
        const invoice = findLast(
          state.invoices,
          (invoice) => invoice.id === action.payload.data.id
        );
        const idx = state.invoices.indexOf(invoice);

        state.invoices[idx] = action.payload.data;
      })
      .addCase(reactivateSubscription.fulfilled, (state, action) => {
        state.error = null;
        state.currentUser.subscription = action.payload;
      })
      .addCase(startLearningPath.fulfilled, (state, action) => {
        state.currentUser.unlockedHubs = action.payload.meta.unlockedHubs;
        state.currentUser.pathProgresses.push(action.payload.data.id);
      })
      .addCase(submitStepStage.fulfilled, (state, action) => {
        const { credits, discount, purchases, unlockedHubs } =
          action.payload.meta || {};

        if (credits) state.currentUser.credits = credits;
        if (discount) state.currentUser.discount = discount;
        if (purchases) state.currentUser.purchases = purchases;
        if (unlockedHubs) state.currentUser.unlockedHubs = unlockedHubs;
      })
      .addCase(updateCurrentUser.fulfilled, setCurrentUser)
      .addCase(HYDRATE, (state, action) => {
        if (state.hydrated) return state;

        return {
          ...state,
          ...action.payload.auth,
          hydrated: true,
        };
      });
  },
});

export {
  deactivateSubscription,
  deleteCurrentUser,
  fetchCurrentUser,
  fetchCurrentUserBookmarks,
  fetchCurrentUserChallenges,
  fetchCurrentUserFollowing,
  fetchCurrentUserFollowers,
  fetchCurrentUserPathProgresses,
  fetchCurrentUserSolutions,
  fetchInvoices,
  followChallenge,
  followTag,
  followUser,
  login,
  payInvoice,
  reactivateSubscription,
  startLearningPath,
  updateCurrentUser,
};

export const selectCurrentUser = createSelector(
  [(state) => state.auth, (state) => state.learners],
  (auth, learners) => {
    const { currentUser } = auth;
    if (!currentUser) return null;

    const user = learners.entities[currentUser.id];
    if (!user) return null;

    return { ...user, ...currentUser };
  }
);

export const selectChallenges = createSelector(
  [(state) => selectCurrentUser(state), (state) => state.challenges],
  (currentUser, challenges) => {
    if (!currentUser) return [];

    return currentUser.unlockedHubs
      .slice()
      .reverse()
      .map((id) => challenges.entities[id])
      .filter((challenge) => !!challenge);
  }
);

const selectSolutionsChallengeIds = createSelector(
  [
    (state) => {
      const currentUser = selectCurrentUser(state);

      return selectSolutionsById(state, currentUser?.solutions);
    },
  ],
  (solutions) => solutions.map((solution) => solution.challenge)
);

export const selectCompletedChallenges = createSelector(
  [
    (state) => selectChallenges(state),
    (state) => selectSolutionsChallengeIds(state),
  ],
  (challenges, solutionsChallengeIds) =>
    challenges.filter((challenge) =>
      solutionsChallengeIds.includes(challenge.id)
    )
);

export const selectInProgressChallenges = createSelector(
  [
    (state) => selectChallenges(state),
    (state) => selectSolutionsChallengeIds(state),
  ],
  (challenges, solutionsChallengeIds) =>
    challenges.filter(
      (challenge) => !solutionsChallengeIds.includes(challenge.id)
    )
);

export const selectCompletedLearningPaths = createSelector(
  [(state) => state.pathProgresses, (state) => state.learningPaths],
  (pathProgressesSlice, learningPaths) => {
    const pathProgresses = Object.values(pathProgressesSlice.entities) || [];

    const completedPathIds = pathProgresses
      .filter((pathProgress) => pathProgress.completedRatio === 1)
      ?.map((pathProgress) => pathProgress.path);

    return completedPathIds
      .map((id) => learningPaths.entities[id])
      .filter(Boolean);
  }
);

export const selectInProgressLearningPaths = createSelector(
  [(state) => state.pathProgresses, (state) => state.learningPaths],
  (pathProgressesSlice, learningPaths) => {
    const pathProgresses = Object.values(pathProgressesSlice.entities) || [];

    const inProgressPathIds = pathProgresses
      .filter((pathProgress) => pathProgress.completedRatio !== 1)
      ?.map((pathProgress) => pathProgress.path);

    return inProgressPathIds
      .map((id) => learningPaths.entities[id])
      .filter(Boolean);
  }
);

export const TALENT_PROFILE_SECTION_VALIDATIONS = {
  bio: (talentProfile) => talentProfile?.insights?.length > 0,
  background: (talentProfile) => !isNaN(talentProfile?.experience),
  featuredProjects: (talentProfile) => talentProfile?.projects?.length > 0,
  workPreferences: (talentProfile) => !!talentProfile?.salary,
};

export const selectCompletedTalentProfileSections = createSelector(
  [(state) => selectCurrentUser(state)],
  (currentUser) =>
    Object.entries(TALENT_PROFILE_SECTION_VALIDATIONS).reduce(
      (acc, [section, validation]) => {
        return validation(currentUser?.talentProfile) ? [...acc, section] : acc;
      },
      []
    )
);

export const selectIsTalentProfileComplete = createSelector(
  [(state) => selectCompletedTalentProfileSections(state)],
  (completedTalentProfileSections) =>
    completedTalentProfileSections.length ===
    Object.keys(TALENT_PROFILE_SECTION_VALIDATIONS).length
);

export const selectIsLoading = (state) => state.auth.status === 'loading';
export const selectInvoices = (state) => state.auth.invoices;

export const {
  lockChallenge,
  removeDiscount,
  signOut,
  spendCredit,
  unlockChallenge,
} = slice.actions;

export default slice.reducer;
