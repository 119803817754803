import useSWR, { useSWRConfig } from 'swr';

export const fetcher = async (...args) => {
  const res = await fetch(...args);
  const data = await res.json();

  if (!res.ok) throw new Error(data.message);
  return data;
};

export default function useFetch(key, options) {
  const { cache } = useSWRConfig();

  const {
    data = {},
    mutate,
    ...rest
  } = useSWR(key, fetcher, {
    errorRetryCount: 0,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    ...options,
  });

  if (options?.fallbackData && !cache.get(key)) {
    mutate(options.fallbackData, { revalidate: false });
  }

  return { data, mutate, ...rest };
}
