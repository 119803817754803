import { createWrapper } from 'next-redux-wrapper';
import { configureStore } from '@reduxjs/toolkit';

import articles from 'features/articles/slice';
import auth from 'features/auth/slice';
import challenges from 'features/challenges/slice';
import comments from 'features/comments/slice';
import conversations from 'features/conversations/slice';
import learners from 'features/learners/slice';
import learningPaths from 'features/learningPaths/slice';
import pathProgresses from 'features/pathProgresses/slice';
import recruiters from 'features/recruiters/slice';
import solutions from 'features/solutions/slice';
import tags from 'features/tags/slice';
import wallOfFame from 'features/wallOfFame/slice';

export const makeStore = () =>
  configureStore({
    reducer: {
      articles,
      auth,
      challenges,
      comments,
      conversations,
      learners,
      learningPaths,
      pathProgresses,
      recruiters,
      solutions,
      tags,
      wallOfFame,
    },
  });

export const wrapper = createWrapper(makeStore);
